var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"comment text-body-2",style:(("margin-left: " + _vm.identation + "px;"))},[_c('div',{staticClass:"first-row",staticStyle:{"margin":"0 auto","overflow":"hidden"}},[_c('div',{staticStyle:{"float":"left"}},[(_vm.comment.repliesTo)?_c('span',[_vm._v("Respuesta de ")]):_vm._e(),_c('strong',[_vm._v(" "+_vm._s(_vm.comment.authorDisplayName)+" ")]),_c('small',[_vm._v(_vm._s(_vm.formatDate(_vm.comment.created)))])]),_c('div',{staticStyle:{"float":"right"}},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((Object.entries(
              _vm.getCommentActions()
            )),function(ref){
            var action = ref[0];
            var actionCallable = ref[1];
return _c('v-list-item',{key:action,attrs:{"x-small":""},on:{"click":actionCallable}},[_c('v-list-item-title',{attrs:{"x-small":""}},[_vm._v(" "+_vm._s(action)+" ")])],1)}),1)],1)],1)]),_c('div',{staticClass:"second-row",staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(_vm.comment.body)+" ")]),(_vm.userIsReplying)?_c('div',{staticStyle:{"margin-top":"15px"}},[_c('NewComment',{attrs:{"teaching-unit-id":_vm.teachingUnitId,"reply-comment-id":_vm.comment.id},on:{"new-comment-added":_vm.addComment}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }