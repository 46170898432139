<template>
  <div>
    <v-form ref="formcomment">
      <v-textarea
        v-model="body"
        :label="$t('components/forum/new_comment/body_hint')"
        autocomplete="off"
        auto-grow
        dark
        outlined
      />
      <div style="margin: 0 auto; overflow: hidden;">
        <v-btn
          color="accent"
          :loading="uploading"
          :disabled="body.length == 0"
          tile
          style="float:right;"
          @click="submitComment"
        >
          {{ $t("components/forum/new_comment/button_publish") }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import Vue from "vue";
import { LogLevels } from "@/models/log/interface";
import * as firebase from "firebase/app";

export default Vue.extend({
  name: "NewComment",
  props: {
    teachingUnitId: {
      type: String,
      required: true
    },
    replyCommentId: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      body: "",
      validComment: false,
      uploading: false
    };
  },
  methods: {
    submitComment() {
      const newComment = {
        authorId: this.$store.state.user.id,
        authorDisplayName: this.$store.state.user.username,
        authorIsAdmin: false, //TODO: Check in the store if user is admin
        body: this.body,
        created: new Date(),
        updated: null,
        upvotes: [],
        revisions: [],
        repliesTo: this.replyCommentId
      };
      this.uploading = true;
      firebase
        .firestore()
        .collection("teaching-units/" + this.teachingUnitId + "/comments")
        .add(newComment)
        .then(docRef => {
          this.$emit("new-comment-added", {...newComment, id: docRef.id });
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.uploading = false;
          this.body = "";
        });
    }
  }
});
</script>

<style scoped></style>
