<template>
  <div class="comment text-body-2" :style="`margin-left: ${identation}px;`">
    <div class="first-row" style="margin: 0 auto; overflow: hidden;">
      <div style="float: left;">
        <span v-if="comment.repliesTo">Respuesta de </span>
        <strong>
          {{ comment.authorDisplayName }}
        </strong>
        <small>{{ formatDate(comment.created) }}</small>
      </div>
      <div style="float: right;">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" x-small dark v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <!-- TODO: Extraer esto en algun sitio lo de [Reportar]-->
            <v-list-item
              v-for="[action, actionCallable] of Object.entries(
                getCommentActions()
              )"
              :key="action"
              x-small
              @click="actionCallable"
            >
              <v-list-item-title x-small>
                {{ action }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div class="second-row" style="white-space: pre-line;">
      {{ comment.body }}
    </div>
    <div v-if="userIsReplying" style="margin-top:15px;">
      <NewComment
        :teaching-unit-id="teachingUnitId"
        :reply-comment-id="comment.id"
        @new-comment-added="addComment"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import * as firebase from "firebase/app";
import NewComment from "@/components/forum/NewComment.vue";
export default Vue.extend({
  name: "PostComment",
  components: { NewComment },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    teachingUnitId: {
      type: String,
      required: true,
    },
    replyLevel: {
      type: Number,
      required: false,
      default: 0,
    },
    userIsAdmin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      userOwnsComment: this.comment.authorId == this.$store.state.user.id,
      userIsEditing: false,
      userIsReplying: false,
      identation: 30 * this.replyLevel,
    };
  },
  methods: {
    addComment(c) {
      this.$emit("new-comment-added", c);
      this.userIsReplying = false;
    },
    formatDate(date) {
      if (!("toLocaleDateString" in date)) {
        return this.enhancedFormatDate(date.toDate());
      } else return this.enhancedFormatDate(date);
    },
    enhancedFormatDate(date) {
      const a = date;
      const b = new Date();
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      const diffDays = Math.floor((utc2 - utc1) / _MS_PER_DAY);
      //console.log('Date: ', date);
      let result = "Hace ";
      if (diffDays == 0) result = "Hoy";
      else if (diffDays == 1) result += " 1 día";
      else if (1 < diffDays && diffDays < 7) result += " " + diffDays + " días";
      //console.log('Days: ', diffDays);

      const diffWeeks = Math.floor(diffDays / 7);
      if (diffWeeks == 1) result += " 1 semana";
      else if (1 < diffWeeks && diffWeeks < 4)
        result += " " + diffWeeks + " semanas";
      //console.log('Weeks: ', diffWeeks);

      const diffMonths = Math.floor(diffWeeks / 4);
      if (diffMonths == 1) result += " 1 mes";
      else if (1 < diffMonths && diffMonths < 12)
        result += " " + diffMonths + " meses";
      //console.log('Months: ', diffMonths);

      const diffYears = Math.floor(diffMonths / 12);
      if (diffYears == 1) result += " 1 año";
      else if (1 < diffYears) result += " " + diffYears + " años";
      //console.log('Years: ', diffYears);
      //console.log('Result: ', result);

      return result;
    },

    getCommentActions() {
      return this.userOwnsComment || this.userIsAdmin
        ? {
            // 'Editar': this.editComment,
            Borrar: this.deleteComment,
            Responder: this.replyComment,
          }
        : {
            Responder: this.replyComment,
            // 'Reportar': this.reportComment
          };
    },
    reportComment() {
      console.log("comment reported");
    },
    deleteComment() {
      const docRef = firebase
        .firestore()
        .collection(`teaching-units/${this.teachingUnitId}/comments`)
        .doc(this.comment.id);
      docRef
        .delete()
        .then(() => {
          this.$store.commit("showMessage", {
            text: "Comentario borrado correctamente",
            color: "success",
          });
          this.$emit("comment-deleted", docRef.id);
        })
        .catch((error) => {
          this.$store.commit("showMessage", {
            text: error,
            color: "error",
          });
        });
    },
    replyComment() {
      this.userIsReplying = !this.userIsReplying;
    },
    editComment() {
      console.log("comment edited");
    },
  },
});
</script>

<style scoped>
.comment {
  background: #23252b;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 6px;
  border-bottom: 3px solid;
  border-bottom-color: crimson;
}

.second-row {
  margin-top: 20px;
}
</style>
