












































































































































































































































































































































































































































import Vue from "vue";
import { LogLevels } from "@/models/log/interface";
import TeachingUnit from "@/models/teaching_unit/interface";
import { PermissionLevel } from "@/models/permissions/interface";
import * as firebase from "firebase/app";
import SubscriptionNeeded from "@/components/SubscriptionNeeded.vue";
import NewComment from "@/components/forum/NewComment.vue";
import PostComment from "@/components/forum/PostComment.vue";

export default Vue.extend({
  name: "TeachingUnit",
  components: { SubscriptionNeeded, NewComment, PostComment },
  props: {
    teachingUnitId: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      loadingComments: false,
      loading: true,
      title: null,
      lessons: [],
      documents: [],
      tests: [],
      publishingDate: null,
      expirationDate: null,
      links: [],
      showError: false,

      subNeededDialog: false,
      //userSubscribedBeforeExpiration: false,
      userSubscriptionStartDate: null,
      unitPublished: false,
      unitStillAvailable: false,
      unitFreeToRegistered: false,
      userIsAdmin: false,
      userIsWhitelisted: false,
      tu: null,

      //New Resource Dialog
      newResourceDialog: false,
      resourceType: null,
      resourceLink: null,
      resourceTitle: null,

      // Edit TU Dialog
      editTUDialog: false,
      newPublishingDate: null,
      hidden: false,
      professor: null,

      // Edit Content Dialog
      editContentDialog: false,
      contentBak: null,

      //Admin mode
      adminMode: false,

      chosenImage: null,
      comments: [],
      sortedComments: [],
    };
  },
  created() {
    this.getAdmin();
    this.getUnit();
  },
  methods: {
    onAddFile() {
      console.log(this.chosenImage[0]);
      const image = this.chosenImage[0];
      const storageRef = firebase.storage().ref();
      const imgRef = storageRef.child(
        `web-assets/tu-cards/${this.teachingUnitId}.jpg`
      );
      imgRef.put(image).then(function(snapshot) {
        console.log("Uploaded a blob or file!");
      });
    },
    getUnit() {
      this.$store
        .dispatch("tests/getTeachingUnit", {
          teachingUnitId: this.teachingUnitId,
        })
        .then((teachingUnit) => {
          this.tu = teachingUnit;
          this.hidden = this.tu.hidden == true;
          this.professor = this.tu.professor || null;
          this.publishingDate = this.tu.publishingDate.toDate();

          if (this.userCanAccessUnit(teachingUnit)) {
            this.title = teachingUnit.title;
            if (teachingUnit.lessons) {
              this.lessons = teachingUnit.lessons;
            }
            if (teachingUnit.documents) {
              this.documents = teachingUnit.documents;
            }
            if (teachingUnit.links) {
              this.links = teachingUnit.links;
            }
            if (teachingUnit.tests) {
              this.tests = teachingUnit.tests;
            }
            this.getComments().then((comments) => {
              this.comments = comments;
              this.sortComments();
            });
          } else {
            if (
              !this.$store.state.user.subscribedSince[PermissionLevel.PREMIUM]
            )
              this.subNeededDialog = true;
            else this.showError = true;
          }
        })
        .catch((error) => {
          this.showError = true;
          console.error(error);
          //this.$store.dispatch("log", { level: LogLevels.ERROR, message: error });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatDate(date) {
      if (!date) return "";
      return date.toDate().toLocaleDateString();
    },
    userCanAccessUnit(tu: TeachingUnit) {
      this.unitPublished = tu.publishingDate < new Date();
      this.unitStillAvailable = !tu.hidden;
      this.unitFreeToRegistered =
        tu.freeToRegistered != undefined && tu.freeToRegistered;
      this.userSubscriptionStartDate = this.$store.state.user.subscribedSince[
        PermissionLevel.PREMIUM
      ];
      this.userIsWhitelisted = false; // TODO: PASAR A COMPROBACION REAL
      if (this.userIsAdmin) {
        return true;
      }
      if (!this.unitStillAvailable) {
        return false;
      }
      if (this.unitPublished && this.unitFreeToRegistered) {
        return true;
      }
      if (!this.userSubscriptionStartDate) {
        return false;
      }
      if (this.unitPublished) {
        return true;
      }
    },
    getAdmin() {
      firebase
        .firestore()
        .collection("resources")
        .doc("admin-users")
        .get()
        .then((doc) => {
          const found = doc.data().list.includes(this.$store.state.user.id);
          this.userIsAdmin = found;
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    async addResource() {
      try {
        if (this.resourceTitle && this.resourceLink && this.resourceType) {
          const docRef = firebase
            .firestore()
            .collection("teaching-units")
            .doc(this.teachingUnitId);
          if (this.resourceType == "links") {
            this.links.push({
              name: this.resourceTitle,
              url: this.resourceLink,
            });
            await docRef.update({
              updated: new Date(),
              links: this.links,
            });
          } else if (this.resourceType == "documents") {
            const rawlink = this.resourceLink.split("/").slice(0, -1);
            rawlink.push("preview");
            const cleanedLink = rawlink.join("/");
            this.documents.push({ name: this.resourceTitle, url: cleanedLink });
            await docRef.update({
              updated: new Date(),
              documents: this.documents,
            });
          } else if (this.resourceType == "lessons") {
            const rawlink = this.resourceLink.split("/");
            rawlink[2] = "player." + rawlink[2];
            rawlink.splice(3, 0, "video");
            const cleanedLink = rawlink.join("/");
            this.lessons.push({ name: this.resourceTitle, url: cleanedLink });
            await docRef.update({
              updated: new Date(),
              lessons: this.lessons,
            });
          } else if (this.resourceType == "tests") {
            this.tests.push({
              name: this.resourceTitle,
              testId: this.resourceLink,
            });
            await docRef.update({
              updated: new Date(),
              tests: this.tests,
            });
          }
          //this.getUnit();
          this.resourceTitle = this.resourceType = this.resourceLink = null;
        } else {
          this.$store.commit("showMessage", {
            text: "Completa los campos obligatorios",
            color: "error",
          });
          this.newResourceDialog = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    prepareEditDialog() {
      const d = this.publishingDate;

      let hour = "" + d.getHours();
      let minutes = "" + d.getMinutes();
      let month = "" + (d.getMonth() + 1);
      let day = "" + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (hour.length < 2) hour = "0" + hour;
      if (minutes.length < 2) minutes = "0" + minutes;
      if (day.length < 2) day = "0" + day;

      this.newPublishingDate =
        [year, month, day].join("-") + "T" + hour + ":" + minutes + ":00";
      this.editTUDialog = true;
    },
    addDays(date, days) {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    async updateTeachingUnit() {
      try {
        if (this.newPublishingDate) {
          const docRef = firebase
            .firestore()
            .collection("teaching-units")
            .doc(this.teachingUnitId);
          await docRef.update({
            title: this.title,
            publishingDate: new Date(this.newPublishingDate),
            expirationDate: this.addDays(new Date(this.newPublishingDate), 7),
            hidden: this.hidden,
            freeToRegistered: this.unitFreeToRegistered,
            professor: this.professor,
            updated: new Date(),
          });
          this.getUnit();
        } else {
          this.$store.commit("showMessage", {
            text: "Completa los campos obligatorios",
            color: "error",
          });
          this.editTUDialog = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    prepareDeleteDialog() {
      if (
        confirm(
          "¿Seguro que quieres eliminar la unidad? Se borrarán todos los enlaces a los vídeos, documentos y tests de manera irreversible."
        )
      ) {
        this.deleteTeachingUnit();
      }
    },
    async deleteTeachingUnit() {
      const docRef = firebase
        .firestore()
        .collection("teaching-units")
        .doc(this.teachingUnitId);
      docRef
        .delete()
        .then(() => {
          this.$store.commit("showMessage", {
            text: "Unidad borrada correctamente",
            color: "success",
          });
          this.$router.push({
            name: "group",
            params: { teachingGroupId: this.tu.category },
          });
        })
        .catch((error) => {
          this.$store.commit("showMessage", {
            text: error,
            color: "error",
          });
        });
    },
    async updateContentTeachingUnit() {
      const docRef = firebase
        .firestore()
        .collection("teaching-units")
        .doc(this.teachingUnitId);

      // Filtrar eliminados
      [this.documents, this.lessons, this.links].forEach(function(array) {
        for (let i = 0; i < array.length; ++i) {
          if (array[i].name == "" && array[i].url == "") {
            array.splice(i, 1);
          }
        }
      });

      [this.tests].forEach(function(array) {
        for (let i = 0; i < array.length; ++i) {
          if (array[i].name == "" && array[i].testId == "") {
            array.splice(i, 1);
          }
        }
      });

      // Integridad enlaces a Vimeo
      for (let i = 0; i < this.lessons.length; ++i) {
        if (!this.lessons[i].url.includes("player")) {
          const rawlink = this.lessons[i].url.split("/");
          rawlink[2] = "player." + rawlink[2];
          rawlink.splice(3, 0, "video");
          const cleanedLink = rawlink.join("/");
          this.lessons[i].url = cleanedLink;
        }
      }

      // Integridad enlaces al Drive
      for (let i = 0; i < this.documents.length; ++i) {
        if (!this.documents[i].url.includes("preview")) {
          const rawlink = this.documents[i].url.split("/").slice(0, -1);
          rawlink.push("preview");
          const cleanedLink = rawlink.join("/");
          this.documents[i].url = cleanedLink;
        }
      }

      await docRef.update({
        links: this.links,
        documents: this.documents,
        lessons: this.lessons,
        tests: this.tests,
        updated: new Date(),
      });
    },
    startContentEdit() {
      this.contentBak = {
        lessons: JSON.stringify(this.lessons),
        tests: JSON.stringify(this.tests),
        links: JSON.stringify(this.links),
        documents: JSON.stringify(this.documents),
      };
      this.editContentDialog = true;
    },
    onContentEditCancel() {
      this.editContentDialog = false;
      this.lessons = JSON.parse(this.contentBak.lessons);
      this.links = JSON.parse(this.contentBak.links);
      this.documents = JSON.parse(this.contentBak.documents);
      this.tests = JSON.parse(this.contentBak.tests);
    },

    // Comments

    addComment(comment) {
      this.comments.push(comment);
      this.sortComments();
    },

    flattenDeep(arr1) {
      return arr1.reduce(
        (acc, val) =>
          Array.isArray(val)
            ? acc.concat(this.flattenDeep(val))
            : acc.concat(val),
        []
      );
    },

    sortedCommentsTreeList(commentId, replyLevel) {
      // console.log('sortedCommentsTreeList', commentId, replyLevel);

      const theComment = this.comments
        .filter((c) => c.id == commentId)
        .map((c) => ({ ...c, replyLevel }));
      // console.log('theComment', theComment);

      const theReplies = this.comments
        .filter((c) => c.repliesTo == commentId)
        .map((c) => this.sortedCommentsTreeList(c.id, replyLevel + 1));
      // console.log('theReplies', theReplies);

      const result = theComment.concat(theReplies);
      // console.log('result', result);

      return result;
    },
    sortComments() {
      // console.log('sortComments');
      this.comments.sort((a, b) => (a.created > b.created ? 1 : -1));
      const availableCommentIds = this.comments.map((c) => c.id);
      this.comments = this.comments.filter(
        (c) => !c.repliesTo || availableCommentIds.includes(c.repliesTo)
      );
      const freeComments = this.comments.filter((c) => !c.repliesTo);
      this.sortedComments = this.flattenDeep(
        freeComments.map((c) => this.sortedCommentsTreeList(c.id, 0))
      );
      this.comments = this.sortedComments;
    },
    getComments() {
      return new Promise((resolve, reject) => {
        firebase
          .firestore()
          .collection("teaching-units/" + this.teachingUnitId + "/comments")
          .get()
          .then((querySnapshot) => {
            const retrievedComments = [];
            for (let i = 0; i < querySnapshot.docs.length; ++i) {
              const doc = querySnapshot.docs[i];
              retrievedComments.push({
                ...doc.data(),
                id: doc.id,
              });
            }
            resolve(retrievedComments);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },

    commentDeleted(comment) {
      const index = this.comments.indexOf(comment);
      if (index > -1) {
        this.comments.splice(index, 1);
      }
      this.sortComments();
    },
  },
});
